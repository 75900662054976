export default {
  badge: 'Distintivo',
  close: 'Chiudi',
  dataIterator: {
    noResultsText: 'Nessun risultato trovato',
    loadingText: 'Caricamento in corso...',
  },
  dataTable: {
    itemsPerPageText: 'Righe per pagina:',
    ariaLabel: {
      sortDescending: 'Ordinati in ordine decrescente.',
      sortAscending: 'Ordinati in ordine crescente.',
      sortNone: 'Non ordinato.',
      activateNone: 'Attiva per rimuovere l\'ordinamento.',
      activateDescending: 'Attiva per ordinare in ordine decrescente.',
      activateAscending: 'Attiva per ordinare in ordine crescente.',
    },
    sortBy: 'Ordina per',
  },
  dataFooter: {
    itemsPerPageText: 'Elementi per pagina:',
    itemsPerPageAll: 'Tutti',
    nextPage: 'Pagina seguente',
    prevPage: 'Pagina precedente',
    firstPage: 'Pagina prima',
    lastPage: 'Pagina ultima',
    pageText: '{0}-{1} di {2}',
  },
  datePicker: {
    itemsSelected: '{0} selezionati',
  },
  noDataText: 'Nessun elemento disponibile',
  carousel: {
    prev: 'Vista precedente',
    next: 'Prossima vista',
    ariaLabel: {
      delimiter: 'Carousel slide {0} of {1}',
    },
  },
  calendar: {
    moreEvents: '{0} di più',
  },
  fileInput: {
    counter: '{0} files',
    counterSize: '{0} files ({1} in totale)',
  },
  timePicker: {
    am: 'AM',
    pm: 'PM',
  },
  pagination: {
    ariaLabel: {
      wrapper: 'Navigazione impaginazione',
      next: 'Pagina seguente',
      previous: 'Pagina precedente',
      page: 'Vai alla pagina {0}',
      currentPage: 'Pagina corrente, pagina {0}',
    },
  },
}
